import {
  BuildingStorefrontIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Lightbox from "react-spring-lightbox";
import { RootState } from "../app/store";
import { ItemLoading } from "./ItemLoading";

export const ItemPage = () => {
  const { id } = useParams();
  const offers = useSelector((state: RootState) => state.offersState.all);
  const offer = offers.find((item) => item.id === id);
  const navigate = useNavigate();
  const [isOpenLightBox, setIsOpenLightBox] = useState(false);
  const [currentImageIndex, setCurrentIndex] = useState(0);

  window.scrollTo({
    top: 0,
  });

  return offer ? (
    <div className="relative flex flex-col md:flex-row md:content-center items-stretch">
      <div className="flex flex-col content-center md:w-1/3">
        <div>
          <ArrowLeftIcon
            onClick={() => {
              navigate(-1);
            }}
            className="w-6 h-6 cursor-pointer rounded-full transition-all duration-100  hover:ring-2 hover:ring-slate-500"
          />
        </div>
        <h1 className="text-center  pt-1 text-2xl font-bold mb-4 underline underline-offset-3 decoration-2">
          {offer?.name}
        </h1>

        {offer?.poster ? (
          <div className="aspect-3/4 overflow-hidden flex items-center justify-center rounded-md ">
            <img src={offer?.poster} alt="" className="bg-clip-content" />
          </div>
        ) : (
          <BuildingStorefrontIcon className="w-fit" />
        )}
      </div>
      <div className="flex flex-col content-center md:w-2/3 text-xl">
        <p className="text-2xl font-bold invisible mb-4">.</p>
        <div className="p-4 align-top">
          <div className="md:inline-block md:w-1/4 pr-4 align-top md:text-right font-bold">
            Описание:
          </div>
          <div className="md:inline-block md:w-3/4  font-normal">
            {offer?.description ? offer?.description : "Отсутствует"}
          </div>
        </div>
        <div className="p-4 align-top">
          <div className="md:inline-block md:w-1/4 pr-4 align-top md:text-right font-bold">
            Адресс:
          </div>
          <div className="md:inline-block md:w-3/4 font-normal">
            {offer?.address ? offer?.address : "Отсутствует"}
          </div>
        </div>
        <div className="p-4 align-top">
          <div className="md:inline-block md:w-1/4 pr-4 align-top md:text-right font-bold">
            Рабочие дни:
          </div>
          <div className="md:inline-block md:w-3/4 font-normal">
            {offer?.workdays ? offer?.workdays.join(",") : "Отсутствует"}
          </div>
        </div>
        <div className="p-4 align-top">
          <div className="md:inline-block md:w-1/4 pr-4 align-top md:text-right font-bold">
            Часы работы:
          </div>
          <div className="md:inline-block md:w-3/4 font-normal">
            {offer?.businesshours ? offer?.businesshours : "Отсутствует"}
          </div>
        </div>
        <div className="p-4 align-top ">
          <div className="md:inline-block md:w-1/4 pr-4 align-top md:text-right font-bold">
            Телефон:
          </div>
          <div className="md:inline-block md:w-3/4 font-normal">
            {offer?.phone ? (
              <a href={"tel:" + offer?.phone}>{offer?.phone}</a>
            ) : (
              <p>Отсутствует</p>
            )}
            <br />{" "}
            {offer?.phone2 ? (
              <a href={"tel:" + offer?.phone2}>{offer?.phone2}</a>
            ) : null}
          </div>
        </div>
        <div className="p-4 align-top ">
          <div className="md:inline-block md:w-1/4 pr-4 align-top md:text-right font-bold">
            Фото:
          </div>
          <div className="md:inline-block md:w-3/4">
            {offer?.media.length ? (
              <div className="relative">
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 overflow-scroll h-fit max-h-96 p-2 md:p-4 justify-self-auto bg-brand-200 rounded-md">
                  {offer.media.map((mediaItem, i) => {
                    if (mediaItem) {
                      return (
                        <img
                          onClick={() => {
                            setCurrentIndex(i);
                            setIsOpenLightBox(true);
                          }}
                          className="cursor-pointer hover:shadow-md transition-all duration-300 hover:shadow-black/50 shadow shadow-black/50 rounded-md overflow-hidden"
                          key={i}
                          src={mediaItem.src}
                          alt={mediaItem.alt}
                        />
                      );
                    } else return null;
                  })}
                </div>
                <Lightbox
                  className="bg-slate-700/60"
                  currentIndex={currentImageIndex}
                  isOpen={isOpenLightBox}
                  onClose={() => setIsOpenLightBox(false)}
                  images={offer.media}
                  onNext={() => setCurrentIndex(currentImageIndex + 1)}
                  onPrev={() => setCurrentIndex(currentImageIndex - 1)}
                  singleClickToZoom
                />
              </div>
            ) : (
              <p>Отсутствует</p>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <ItemLoading />
  );
};
