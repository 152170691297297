import { Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";

export const Modal = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    if (window.localStorage.getItem("modalNotOpened")) {
      setIsModalOpen(false);
    }
  }, []);

  return (
    <div className="relative flex justify-center ">
      <Transition
        as={Fragment}
        show={isModalOpen}
        appear
        enter="transform transition duration-700"
        enterFrom="opacity-0 scale-50"
        enterTo="opacity-100 scale-100"
        leave="duration-400 transition transform"
        leaveFrom="opacity-100 scale-100 "
        leaveTo="opacity-0 scale-75 "
      >
        <div className="absolute z-10 w-80% md:w-50% pointer rounded-md bg-white shadow-slate-600/60 shadow-lg border-2 border-brand-400">
          <div className="p-4 max-w-2xl flex flex-col items-center">
            <h3 className="text-center text-lg md:text-xl font-bold mb-2 md:mb-4">
              Приветствуем вас на сайте Veguk Gyeongju!
            </h3>
            <p className="md:text-lg mb-2 md:mb-4 text-center">
              Здесь вы можете быстро найти заведения и услуги от русскоязычных
              жителей в кенджу. Если вы предприниматель или оказываете услуги
              можете отправить свои данные по инструкции и вас смогут быстро
              найти жители кенджу.
            </p>
            <button
              className="px-4 py-1 text-lg text-white transition-colors duration-100 rounded-md bg-slate-500 capitalize hover:ring-1 hover:ring-slate-600 active:ring-0 active:bg-slate-600 mb-2"
              onClick={() => {
                window.localStorage.setItem("modalNotOpened", "true");
                setIsModalOpen(false);
              }}
            >
              Закрыть
            </button>
          </div>
        </div>
      </Transition>
    </div>
  );
};
