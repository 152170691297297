import { Routes, Route } from "react-router-dom";
import "./App.css";
import { Home } from "./features/homepage/Home";
import { About } from "./features/about/About";
import { ItemPage } from "./common/ItemPage";
import { useEffect } from "react";
import { getOffers } from "./app/reducers";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./app/store";
import { Layout } from "./features/Layout";
import { Manual } from "./features/manual/Manual";
import { Articles } from "./features/articles/Articles";

function App() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    console.log("app called");
    dispatch(getOffers());
  }, [dispatch]);

  return (
    <div className="App bg-background-200 text-slate-700 min-h-screen">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/item/:id" element={<ItemPage />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/about" element={<About />} />
          <Route path="/manual" element={<Manual />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
