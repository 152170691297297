import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImagesListType } from "react-spring-lightbox";
import type { RootState } from "./store";

// Define a type for the slice state
export type OffersType = {
  id: string;
  name: string;
  phone: string;
  phone2: string;
  address: string;
  poster: string;
  media: ImagesListType;
  description: string;
  tag: Array<string>;
  workdays: Array<string>;
  businesshours: string;
};
export type OffersStateType = {
  all: Array<OffersType>;
  filtered: Array<OffersType>;
  offersCount: number;
  offersLoading: boolean;
};

// First, create the thunk
export const getOffers = createAsyncThunk("offers/requestStatus", async () => {
  const response = await fetch("/.netlify/functions/get_offers", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    },
  }).then((response) => response.json());
  return response.data;
});

// Define the initial state using that type
const initialState = {
  all: [{ id: "noOffers" }],
  filtered: [{ id: "none" }],
  offersCount: 8,
  offersLoading: false,
} as OffersStateType;

export const offersSlice = createSlice({
  name: "offersState",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    searchFilter(state, action: PayloadAction<string>) {
      state.filtered = state.all.filter((offer) => {
        return offer.name.toLowerCase().includes(action.payload.toLowerCase());
      });
    },
    tagFilter(state, action: PayloadAction<string>) {
      state.filtered = [];
      state.all.forEach((offer) => {
        offer.tag.forEach((tag) => {
          if (tag.toLowerCase() === action.payload.toLowerCase()) {
            state.filtered.push(offer);
          }
        });
      });
    },
    noFilter(state) {
      state.filtered = state.all;
    },
    addOffersCount(state) {
      state.offersCount += 4;
    },
    resetOffersCount(state) {
      state.offersCount = 8;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed

    builder.addCase(getOffers.pending, (state) => {
      // insert offers to the state array
      state.offersLoading = true;
      return state;
    });
    builder.addCase(getOffers.fulfilled, (state, action) => {
      // insert offers to the state array
      state.all = [...action.payload];
      state.filtered = [...action.payload];
      state.offersLoading = false;
      return state;
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const {
  searchFilter,
  tagFilter,
  noFilter,
  addOffersCount,
  resetOffersCount,
} = offersSlice.actions;
export const offersState = (state: RootState) => state.offersState;

export default offersSlice.reducer;
