//import { Card } from "../../common/Card";

export const Articles = () => {
  /* const navig = [
    {
      id: "234",
      name: "Статьи",
    },
  ];
  const articles = [
    {
      id: "123",
      name: "Статья",
      img: "",
      description: "",
      type: "article",
    },
  ];

  /*return (


    <section className="mb-8">
      <nav className="text-md  flex items-center py-4 px-4 mb-6 space-x-6 overflow-x-auto whitespace-nowrap bg-slate-400">
        {navig.map((nav) => {
          return (
            <button
              key={nav.id}
              className="px-4 py-2 text-white rounded-md bg-slate-500 capitalize transition-colors duration-100  hover:ring-1 hover:ring-slate-700 active:bg-slate-600 active:ring-0"
            >
              {nav.name}
            </button>
          );
        })}
      </nav>
      <div className="grid grid-cols-2 sm:grid-cols-3  lg:grid-cols-4 gap-6 lg:gap-x-12">
        {articles.map((article) => {
          return (
            <Card
              key={article.id}
              poster={article.img}
              name={article.name}
              description={article.description}
              phone={""}
              media={""}
              phone2={""}
              tag={[]}
              address={""}
              id={""}
            />
          );
        })}
      </div>
    </section>

    
  );  */
  return (
    <section className="mb-8 min-h-screen">
      <h1 className="mt-32 text-center font-bold text-2xl lg:text-3xl">
        Раздел на стадии разработки
      </h1>
    </section>
  );
};
