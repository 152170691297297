export const Manual = () => {
  return (
    <div className="p-2">
      <h1 className="text-2xl font-bold text-center mb-4">Руководство</h1>
      <div>
        <p className="indent-4 mb-2">
          Если вы являетесь владельцем бизнеса или оказываете частные услуги вы
          можете отправить свои данные в форму по этой{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLSe6h0HNXy13U35CbAatvChhHGK0YJSO_XKR5qazKho8MmMlAw/viewform"
            className="underline text-brand-600 font-bold"
          >
            ссылке
          </a>
          .{" "}
          <a
            target="_blank"
            rel="noreferrer"
            className="underline text-brand-600 font-bold"
            href="https://docs.google.com/forms/d/e/1FAIpQLSe6h0HNXy13U35CbAatvChhHGK0YJSO_XKR5qazKho8MmMlAw/viewform?usp=pp_url&entry.1241324562=%D0%9F%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B4%D1%87%D0%B8%D0%BA&entry.1401911795=%D0%92%D0%B0%D1%81%D1%8F+%D0%9F%D1%83%D0%BF%D0%BA%D0%B8%D0%BD&entry.2058783279=01012345678&entry.1019630402=%D0%9F%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B4+%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D0%BE%D0%B2,+%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D1%8C+%D1%81+%D0%BD%D0%B0%D1%87%D0%B0%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE%D0%BC,+%D0%B1%D0%BE%D0%BB%D1%8C%D0%BD%D0%B8%D1%86%D1%8B,+%D0%BF%D1%83%D0%B4%D0%BE%D0%BD%D1%81%D0%B0%D0%BD%D1%8B&entry.1228063584=10:00-20:30&entry.2036940058=%D0%BF%D0%BE%D0%BD%D0%B5%D0%B4%D0%B5%D0%BB%D1%8C%D0%BD%D0%B8%D0%BA&entry.2036940058=%D0%B2%D1%82%D0%BE%D1%80%D0%BD%D0%B8%D0%BA&entry.2036940058=%D1%81%D1%80%D0%B5%D0%B4%D0%B0&entry.2036940058=%D1%87%D0%B5%D1%82%D0%B2%D0%B5%D1%80%D0%B3&entry.2036940058=%D0%BF%D1%8F%D1%82%D0%BD%D0%B8%D1%86%D0%B0&entry.2036940058=%D1%81%D1%83%D0%B1%D0%B1%D0%BE%D1%82%D0%B0&entry.1391317329=https://t.me/vasyapupkintelegram"
          >
            Образец
          </a>
          .
        </p>
        <p className="indent-4 mb-2 ">
          <strong>Для добавления или обновления информации</strong> на сайте
          нужно отправить актуальную информацию и фотографии в форме выше.
          Добавление на сайт в течении 1-3 дней
        </p>
        <div className="border-b-spacing-3 border-b-2 border-b-slate-600 my-2 md:my-5"></div>
        <p className="indent-4 mb-2">
          <strong>Какие виды услуг запрещены для размещения на сайте?</strong>{" "}
          <br /> Все виды услуг требующие возрастных ограничений 19+(взрослая
          тематика, сигареты, алкоголь, вейпы и т.д.), информация о
          трудоустройстве(временно), продажа/покупка животных, азартные игры и
          гемблинг, схемы заработка. Подобная информация не будет размещаться на
          сайте. Для поиска такой информации обращайтесь в соотетствующие
          источники информации.{" "}
        </p>
        <div className="border-b-spacing-3 border-b-2 border-b-slate-600 my-2 md:my-5"></div>
        <p className="indent-4 mb-2">
          <strong className="font-bold">Соглашение</strong> Отправляя свои
          данные через форму вы соглашаетесь на размещение их на сайте. В случае
          использования выданной вами информации третьими лицами для нанесения
          вам ущерба, администрация сайта ответственности не несет. Для удаления
          вашей информации отправьте email с просьбой удалить вашу информацию с
          сайта, в сообщении укажите название вашей страницы или пришлите ссылку
          с id вашей страницы
        </p>
      </div>
    </div>
  );
};
