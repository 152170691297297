export const tags = [
  { name: "Продавец/Консультант", id: "211" },

  { name: "Страховка", id: "212" },
  { name: "Салон Красоты", id: "213" },
  { name: "Переводчик", id: "214" },
  { name: "Сервис/Ремонт Электроники", id: "215" },
  { name: "Образование", id: "216" },
  { name: "Пекарня", id: "217" },
  { name: "Магазин", id: "218" },
  { name: "Кафе/Ресторант/Бар", id: "219" },
  { name: "Доставка", id: "220" },
  { name: "Стол Услуг", id: "221" },
  { name: "Салон Сотовой Связи", id: "222" },
  { name: "Караоке", id: "223" },
  { name: "Международная почта", id: "224" },
  { name: "Фастфуд", id: "225" },
];
