import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addOffersCount,
  noFilter,
  resetOffersCount,
  tagFilter,
} from "../../app/reducers";
import { RootState } from "../../app/store";
import { Card } from "../../common/Card";
import { HomeLoading } from "../../common/HomeLoading";
import { tags } from "../../common/tags";

export const Offers = () => {
  const offers = useSelector((state: RootState) => state.offersState.filtered);
  const isLoading = useSelector(
    (state: RootState) => state.offersState.offersLoading
  );
  const offersCount = useSelector(
    (state: RootState) => state.offersState.offersCount
  );
  const dispatch = useDispatch();

  const [activeFilter, setActiveFilter] = useState("");

  return (
    <section className="mb-8">
      <div className="text-md  flex items-center py-4 px-4 mb-6 space-x-6 overflow-x-auto whitespace-nowrap bg-brand-200">
        {tags.map((tag) => {
          if (tag) {
            return (
              <button
                key={tag.id}
                className={
                  activeFilter === tag.id
                    ? "px-4 py-2 text-white rounded-md bg-slate-500 capitalize transition-colors duration-100  hover:ring-1 hover:ring-brand-500 active:bg-slate-500 active:ring-0 select-none "
                    : "px-4 py-2 text-white rounded-md bg-brand-400 capitalize transition-colors duration-100  hover:ring-1 hover:ring-brand-500 active:bg-slate-500 active:ring-0 select-none "
                }
                onClick={() => {
                  if (activeFilter === tag.id) {
                    setActiveFilter("");
                    dispatch(noFilter());
                  } else {
                    setActiveFilter(tag.id);
                    dispatch(tagFilter(tag.name));
                  }
                  dispatch(resetOffersCount());
                }}
              >
                {tag.name}
              </button>
            );
          } else {
            return null;
          }
        })}
      </div>
      {isLoading ? (
        <HomeLoading />
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 lg:gap-x-12">
          {offers.map((offer, i) => {
            if (i < offersCount) {
              return (
                <Card
                  key={offer.id}
                  id={offer.id}
                  poster={offer.poster}
                  name={offer.name}
                  description={offer.description}
                  phone={offer.phone}
                  phone2={offer.phone2}
                  address={offer.address}
                  media={offer.media}
                  tag={offer.tag}
                  workdays={offer.workdays}
                  businesshours={offer.businesshours}
                />
              );
            } else return null;
          })}
        </div>
      )}
      {offersCount >= offers.length ? null : (
        <div className="pt-8 flex justify-center">
          <button
            onClick={() => {
              dispatch(addOffersCount());
            }}
            className=" px-4 py-2 text-white rounded-md bg-slate-500 capitalize transition-colors duration-100  hover:ring-1 hover:ring-slate-600 active:bg-slate-500 active:ring-0"
          >
            Еще...
          </button>
        </div>
      )}
    </section>
  );
};
