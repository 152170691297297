import {
  ArrowLeftIcon,
  BuildingStorefrontIcon,
} from "@heroicons/react/24/outline";

export const ItemLoading = () => {
  return (
    <div className="relative animate-pulse flex flex-col md:flex-row md:content-center items-stretch">
      <div className="flex flex-col content-center md:w-1/3">
        <div>
          <ArrowLeftIcon className="w-6 h-6 cursor-pointer rounded-full transition-all duration-100  hover:ring-2 hover:ring-slate-500" />
        </div>
        <h1 className=" mx-auto pt-1 text-2xl font-bold mb-4 bg-brand-600/30 w-fit">
          <strong className="invisible">Name</strong>
        </h1>
        <BuildingStorefrontIcon className="w-full text-brand-600/30" />
      </div>
      <div className="flex flex-col content-center md:w-2/3 text-xl">
        <p className="text-2xl font-bold invisible mb-4">.</p>
        <div className="p-4 align-top">
          <div className=" md:inline-block md:w-1/4 pr-4 align-top md:text-right font-bold">
            Описание:
          </div>
          <div className="bg-brand-600/30 md:inline-block md:w-3/4  font-normal">
            <strong className="invisible">{"Отсутствует"}</strong>
          </div>
        </div>
        <div className="p-4 align-top">
          <div className=" md:inline-block md:w-1/4 pr-4 align-top md:text-right font-bold">
            Адресс:
          </div>
          <div className="bg-brand-600/30 md:inline-block md:w-3/4 font-normal">
            <strong className="invisible">{"Отсутствует"}</strong>
          </div>
        </div>
        <div className="p-4 align-top">
          <div className=" md:inline-block md:w-1/4 pr-4 align-top md:text-right font-bold">
            Рабочие дни:
          </div>
          <div className=" bg-brand-600/30 md:inline-block md:w-3/4 font-normal">
            <strong className="invisible">{"Отсутствует"}</strong>
          </div>
        </div>
        <div className="p-4 align-top">
          <div className=" md:inline-block md:w-1/4 pr-4 align-top md:text-right font-bold">
            Часы работы:
          </div>
          <div className=" bg-brand-600/30 md:inline-block md:w-3/4 font-normal">
            <strong className="invisible">{"Отсутствует"}</strong>
          </div>
        </div>
        <div className="p-4 align-top ">
          <div className=" md:inline-block md:w-1/4 pr-4 align-top md:text-right font-bold">
            Телефон:
          </div>
          <div className=" bg-brand-600/30 md:inline-block md:w-3/4 font-normal">
            <strong className="invisible">Отсутствует</strong>
          </div>
        </div>
        <div className="p-4 align-top ">
          <div className=" md:inline-block md:w-1/4 pr-4 align-top md:text-right font-bold">
            Фото:
          </div>
        </div>
      </div>
    </div>
  );
};
