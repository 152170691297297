import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  CursorArrowRaysIcon,
  DocumentTextIcon,
  HomeIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

const solutions = [
  {
    name: "Главная",
    href: "/",
    icon: HomeIcon,
  },
  // {
  //   name: "Заведения/Услуги",
  //   href: "/offers",
  //   icon: BuildingStorefrontIcon,
  // },
  {
    name: "Статьи",
    href: "/articles",
    icon: DocumentTextIcon,
  },
  {
    name: "Руководство",
    href: "/manual",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "О проекте",
    href: "/about",
    icon: CursorArrowRaysIcon,
  },
];

const Header = () => {
  return (
    <Popover className="relative mb-8">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <h2 className="text-2xl  font-bold logo_style">Veguk Gyeongju</h2>
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-brand-400 p-2 hover:bg-brand-500 text-white focus:outline-none ">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex text-lg">
            {solutions.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="text-base font-medium border-b border-transparent transition-colors duration-300 transform hover:border-slate-800"
              >
                {item.name}
              </Link>
            ))}
          </Popover.Group>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden z-10"
        >
          <div className=" rounded-lg bg-background-200 shadow-lg ring-2 ring-black ring-opacity-10">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="uppercase text-2xl font-bold">Veguk.info</h2>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-brand-400 p-2 hover:bg-brand-500 text-white focus:outline-none">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {solutions.map((item) => (
                    <Popover.Button
                      as={Link}
                      key={item.name}
                      to={item.href}
                      className="-m-3 flex items-center rounded-md p-3  group"
                    >
                      <item.icon
                        className="h-6 w-6 flex-shrink-0 text-brand-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-lg font-medium border-b border-transparent transition-colors duration-300 transform group-hover:border-gray-800 group-hover:text-gray-800 ">
                        {item.name}
                      </span>
                    </Popover.Button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export { Header, solutions };
