import { useState } from "react";
import { useDispatch } from "react-redux";
import { searchFilter } from "../../app/reducers";
import { Modal } from "../../common/Modal";
import { Offers } from "../offers/Offers";

export const Home = () => {
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  const inputHandler = (text: string) => {
    setInputValue(text);
    dispatch(searchFilter(text));
  };

  return (
    <>
      <div className="flex justify-center gap-x-2 md:gap-x-4 mb-4">
        <input
          type="text"
          className="px-2 py-1 w-2/3 lg:w-1/2 text-lg rounded-md outline-none focus:ring-1 focus:ring-slate-600"
          name="search"
          id=""
          placeholder="поиск..."
          value={inputValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            inputHandler(event.target.value);
          }}
        />
        <button className="px-4 py-1 text-lg text-white transition-colors duration-100 rounded-md bg-slate-500 capitalize hover:ring-1 hover:ring-slate-600 active:ring-0 active:bg-slate-600 ">
          Поиск
        </button>
      </div>
      <div className="min-h-24 p-2 md:p-4 mb-8 w-full bg-slate-600 text-white flex flex-col justify-center">
        <h2 className="text-lg text-bold lg:text-2xl text-center uppercase">
          {" "}
          Все русскоязычные заведения Кёнджу в одном месте с телефонами и
          адресами{" "}
        </h2>
      </div>
      <Modal />
      <Offers />
    </>
  );
};
