import { Link } from "react-router-dom";
import { BuildingStorefrontIcon } from "@heroicons/react/24/outline";
import { OffersType } from "../app/reducers";

export const Card = (props: OffersType) => {
  /*const articleStyle =
    "absolute bottom-0 px-2 py-1 text-white bg-gradient-to-r from-slate-600/90 to-slate-600/50 w-full rounded-b-md";*/
  const offerStyle =
    "absolute bottom-0 px-2 py-1 text-white bg-gradient-to-r from-brand-600/90 to-brand-600/50 w-full rounded-b-md ";

  return (
    <Link
      to={"item/" + props.id}
      className="group relative rounded-md cursor-pointer flex flex-col justify-center aspect-3/4 hover:shadow-md transition-all duration-300 hover:shadow-black/50 shadow shadow-black/50 overflow-hidden "
    >
      {props.poster ? (
        <img
          className="rounded-md bg-clip-content bg-brand-600  transition-all duration-300  "
          src={props.poster}
          alt={props.name}
        />
      ) : (
        <div className="h-60 flex justify-center">
          <BuildingStorefrontIcon className="w-2/3 " />
        </div>
      )}
      <div className="absolute rounded-md left-0 top-0 right-0 bottom-0 group-hover:bg-black/25 transition-colors duration-300"></div>
      <div className={offerStyle}>
        <h3 className="text-lg md:text-xl  w-fit transition-all duration-300 transform group-hover:border-white border-b-2 border-transparent font-semibold capitalize">
          {props.name}
        </h3>
        <p className=" transition-all duration-300 transform font-semibold">
          {props.tag?.join(" | ")}
        </p>
      </div>
    </Link>
  );
};
