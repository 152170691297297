export const About = () => {
  return (
    <div className="p-2">
      <h1 className="text-2xl text-center font-bold mb-4">О проекте</h1>
      <p className="indent-4 mb-2">
        <strong className="font-bold">Проект Veguk Gyeongju</strong> создан для
        взаимной помощи иммигрантов в Южной Корее. Большинство из нас приезжая
        на заработки в Корею сталкивается с проблемами не понимания языка,
        визовыми вопросами, скучаем по родной кухне, хотим отправить посылки
        родственникам на родине, отправляем детей в садики и школы. Но многие
        находятся в Корее уже давно среди них есть те кто знает язык, открыл
        кафе или магазин, занимается вопросами визы и обязательной страховки,
        занимается международной перевозкой.
      </p>

      <div className="border-b-spacing-3 border-b-2 border-b-slate-600 my-2 md:my-5"></div>
      <p className="indent-4 mb-2">
        <strong className="font-bold">Для того что бы помочь</strong> найти те
        или иные товары и услуги проект Veguk Gyeongju собирает и предоставляет
        в удобном формате информацию о действующих переводчиках, визовых
        центров, офисов приема и выдачи международных посылок, кафе, магазинов,
        караоке и прочих услуг, предоставляемые русскоязычными иммигрантами. На
        данный момент информация размещенная на сайте ограниченна одним городом
        Кенджу. В дальнейшем планируется размещать информацию по всем городам
        Кореи.
      </p>
    </div>
  );
};
