import { BuildingStorefrontIcon } from "@heroicons/react/24/outline";

export const HomeLoading = () => {
  return (
    <div>
      <div className="animate-pulse grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 lg:gap-x-12 text-brand-500/50">
        {[1, 2, 3, 4].map((i) => {
          return (
            <div className="relative rounded-md cursor-pointer flex flex-col justify-center aspect-3/4 shadow shadow-black/50">
              <div className="h-60 flex justify-center">
                <BuildingStorefrontIcon className="w-2/3 " />
              </div>
              <div className="absolute rounded-md left-0 top-0 right-0 bottom-0 "></div>
              <div className="absolute bottom-0 px-2 py-1 bg-gradient-to-r from-brand-600/60 to-brand-600/40 w-full rounded-b-md">
                <h3 className="text-lg md:text-xl invisible w-fit border-b-2 border-transparent font-semibold capitalize">
                  Loading
                </h3>
                <p className=" invisible font-semibold">. . .</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
