import { Link } from "react-router-dom";
import { solutions } from "../header/Header";

export const Footer = () => {
  return (
    <footer className=" mt-4 py-4 flex flex-col gap-y-4">
      <div className=" flex justify-center gap-x-4 text-lg">
        {solutions.map((item) => (
          <Link
            key={item.href}
            to={item.href}
            className=" font-medium  border-b border-transparent transition-colors duration-300 transform hover:border-gray-800 hover:text-gray-800"
          >
            {item.name}
          </Link>
        ))}
      </div>
      <div className="mx-auto w-fit">
        © 2023 Veguk Gyeongju All rights reserved.
      </div>
    </footer>
  );
};
